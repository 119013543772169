import React from "react";
import AchievementStylePart1 from "./AchievementStylePart1.css";
import AchieverHtml from "../../AboutusPage/AboutPagePart2/AchieverHtml";
import bharaniImage from "./image/bharaniImage.jpg";

export default function AchievementPagePart1() {
  return (
    <section id="AchievementPagePart1">
      <div className="AchievementPageParent">
        <div className="UPSCAchieverTitle">UPSC Exam Achiever:</div>
        <div className="UPSCAchieverDetails">
          <div className="AchievementPageAchieverContent">
            <div className="AchieverName">Bharani.M</div>
            <div className="AchieverRank">UPSC AIR-326 (2019 Batch)</div>
            <div className="AchieverDescription">Deputy Director in Delhi</div>
            <div className="AchievementPageAchieverCurrentDetails">
              "Pride of Thiru's IAS Academy". Shri. M. Bharani, deputy director
              in Delhi, is a role model for all aspirants. Bharani was an
              engineering background student who had an 8 hours job during his
              preparation. With all his efforts and proper guidance from our
              founder, he achieved his dream.
            </div>
          </div>
          <div className="AchievementPageAchieverImage">
            <img src={bharaniImage} />
          </div>
        </div>
      </div>
    </section>
  );
}
