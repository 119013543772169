import React from "react";
import HomePage from "../../HomePage/HomePage";
import FooterHtml from "../../FooterPage/FooterHtml";
import RegionalHtml from "./RegionalHtml";
export default function RegionalExamPage() {
  return (
    <section id="RegionalExamPage">
      <RegionalHtml />
    </section>
  );
}
