import React from "react";
import HomePage from "../../HomePage/HomePage";
import FooterHtml from "../../FooterPage/FooterHtml";
import UpscCourseHtml from "./UpscCourseHtml";
export default function UPSC() {
  return (
    <section id="UPSCPage">
      <UpscCourseHtml />
    </section>
  );
}
